<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <loader-page v-if="isLoading"></loader-page>
            <template v-else>
                <!-- Search bar & create new community -->
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-5">
                        <form @submit.prevent="filterGroup">
                            <div class="uk-inline">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                <input 
                                    type="text" 
                                    class="uk-input" 
                                    placeholder="Search Community"
                                    v-model="meta.name"
                                >
                            </div>
                        </form>
                    </div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5">
                        <button 
                            v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])"
                            type="button" 
                            class="uk-button uk-button-primary uk-border-rounded uk-width-1-1"
                            @click="showCreateOrEditGroupModalBox({ 
                                type: 'create',
                                id: null,
                                name: '', 
                                description: '', 
                                logo: null, 
                                bannerImage: null, 
                                adminData: null,
                                jobPreferenceData: null
                            })"
                        >Create</button>
                    </div>
                </div>
                <!-- End search bar & create new community -->

                <!-- Community list -->
                <div class="uk-card uk-card-default uk-margin-top">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-middle uk-table-medium uk-table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Admin</th>
                                    <th>Total Member</th>
                                    <th class="uk-text-center">Logo</th>
                                    <th class="uk-text-center">Banner Image</th>
                                    <th class="uk-width-small uk-text-center">Action</th>
                                </tr>
                            </thead>
                            <loading-table v-if="tableLoading" :colspan="7"></loading-table>
                            <template v-else>
                                <tbody v-if="group.docs.length > 0">
                                    <tr
                                        v-for="(group, index) in group.docs"
                                        :key="index"
                                    >
                                        <td>{{ preview({ string: group.name }) }}</td>
                                        <td>{{ preview({ string: group.description }) }}</td>
                                        <td>{{ preview({ string: group.admin && group.admin.fullname ? group.admin.fullname : null }) }}</td>
                                        <td>{{ preview({ string: group.count_follower }) }}</td>
                                        <td class="uk-text-center">
                                            <img 
                                                v-if="dataExist({ data: group.logo })"
                                                :src="`${images}/check-true.svg`" 
                                                alt="Group logo checklist"
                                                style="width:20px"
                                            >
                                            <img 
                                                v-else
                                                :src="`${images}/check-false.svg`" 
                                                alt="Group logo uncheck"
                                                style="width:20px"
                                            >
                                        </td>
                                        <td class="uk-text-center">
                                            <img 
                                                v-if="dataExist({ data: group.banner_image })"
                                                :src="`${images}/check-true.svg`" 
                                                alt="Group logo checklist"
                                                style="width:20px"
                                            >
                                            <img 
                                                v-else
                                                :src="`${images}/check-false.svg`" 
                                                alt="Group logo uncheck"
                                                style="width:20px"
                                            >
                                        </td>
                                        <td class="uk-width-small uk-text-center">
                                            <button 
                                                type="button"
                                                class="uk-button uk-button-small uk-button-default"
                                            >Actions</button>
                                            <div uk-dropdown="mode: click">
                                                <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                    <li><a @click="groupDetail({ groupId: group._id })">Details</a></li>
                                                    <li><a @click="groupTopic({ groupId: group._id })">Topic</a></li>
                                                    <li v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-nav-divider"></li>
                                                    <li v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                                        <a 
                                                            @click="showCreateOrEditGroupModalBox({ 
                                                                type: 'edit',
                                                                id: group._id,
                                                                name: group.name, 
                                                                description: group.description, 
                                                                logo: group.logo, 
                                                                bannerImage: group.banner_image, 
                                                                adminData: group.admin ? group.admin : null,
                                                                jobPreferenceData: group.job_preference_ids ? group.job_preference_ids : null
                                                            })"
                                                        >Edit</a>
                                                    </li>
                                                    <li v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                                        <a 
                                                            @click="showDeleteGroupModalBox({
                                                                id: group._id,
                                                                name: group.name, 
                                                                description: group.description, 
                                                                logo: group.logo, 
                                                                bannerImage: group.banner_image, 
                                                                adminData: group.admin ? group.admin : null,
                                                                jobPreferenceData: group.job_preference_ids ? group.job_preference_ids : null
                                                            })"
                                                        >Delete</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="7"/>
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="group.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    ></pagination>
                </div>
                <!-- End community list -->

                <!-- Create or edit community modal box -->
                <div id="create-or-edit-group-modal-box" class="uk-flex-top create-or-edit-group-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedGroupData" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">{{ actionType === 'create' ? 'Create Form' : 'Edit Form' }}</h2>
                        </div>
                        <form @submit.prevent="createOrEditGroup">
                            <div class="uk-padding-small uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="name" class="uk-form-label">Name <span class="uk-text-danger">*</span></label>
                                    <input
                                        :maxlength="nameLength"
                                        id="name"
                                        name="name"
                                        type="text" 
                                        class="uk-input uk-border-rounded"
                                        v-model="selectedGroupData.name"
                                        v-validate="'required'"
                                        required
                                    >
                                    <div uk-grid>
                                    <div class="uk-inline uk-width-1-2">
                                      <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                                    </div>
                                    <div class="uk-width-1-2 uk-text-right">
                                      <span class="uk-text-small">{{ selectedGroupData.name.length }}/{{ nameLength }} Characters</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="description" class="uk-form-label">Description <span class="uk-text-danger">*</span></label>
                                    <input 
                                        id="description"
                                        name="description"
                                        type="text" 
                                        class="uk-input uk-border-rounded"
                                        v-model="selectedGroupData.description"
                                        v-validate="'required'"
                                        required
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="adminData" class="uk-form-label">Admin <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="adminData"
                                        placeholder="Select Admin"
                                        label="fullname"
                                        track-by="_id"
                                        :options="adminOptions"
                                        v-model="selectedGroupData.admin_data"
                                    ></multiselect>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="logo" class="uk-form-label">Logo</label>
                                    <div v-if="fileAttachmentLogo.url && fileAttachmentLogo.file" id="logo">
                                        <div class="uk-inline">
                                            <img 
                                                :src="fileAttachmentLogo.url" 
                                                alt="Group logo preview"
                                                class="uk-width-auto uk-height-medium"
                                            >

                                            <div class="uk-position-top-right">
                                                <button
                                                    type="button"
                                                    class="icon-close-button"
                                                    @click="resetFileAttachmentLogo"
                                                >
                                                    <img 
                                                        :src="`${images}/close.svg`" 
                                                        alt="Close icon"
                                                    >
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else id="logo">
                                        <button 
                                            type="button"
                                            class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle uk-flex-between"
                                            @click="triggerUploadGroupLogo"
                                        >
                                            <span><img :src="`${images}/cloud-upload-white.svg`" alt="Cloud upload icon"></span>
                                            <span class="uk-margin-small-left">Upload Image</span>
                                            <input 
                                                name="logo"
                                                type="file"
                                                ref="logo"
                                                accept="image/png, image/jpeg, image/jpg"
                                                style="display: none;"
                                                @change="previewGroupLogo({ event: $event })"
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="bannerImage" class="uk-form-label">Banner Image</label>
                                    <div v-if="fileAttachmentBanner.url && fileAttachmentBanner.file" id="bannerImage">
                                        <div class="uk-inline">
                                            <img 
                                                :src="fileAttachmentBanner.url" 
                                                alt="Group logo preview"
                                                class="uk-width-auto uk-height-medium"
                                            >

                                            <div class="uk-position-top-right">
                                                <button
                                                    type="button"
                                                    class="icon-close-button"
                                                    @click="resetFileAttachmentBanner"
                                                >
                                                    <img :src="`${images}/close.svg`" alt="Close icon">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else id="bannerImage">
                                        <button 
                                            type="button"
                                            class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle uk-flex-between"
                                            @click="triggerUploadGroupBanner"
                                        >
                                            <span><img :src="`${images}/cloud-upload-white.svg`" alt="Cloud upload icon"></span>
                                            <span class="uk-margin-small-left">Upload Image</span>
                                            <input 
                                                name="banner"
                                                type="file"
                                                ref="banner"
                                                accept="image/png, image/jpeg, image/jpg"
                                                style="display: none;"
                                                @change="previewGroupBanner({ event: $event })"
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="jobPreferenceData" class="uk-form-label">Related Job Preference(s) <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="jobPreferenceData"
                                        name="job_preferences"
                                        track-by="_id"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :custom-label="customLabelJobPreferenceOptions"
                                        :options="jobPreferenceOptions"
                                        :allow-empty="false"
                                        v-model="selectedGroupData.job_preference_data"
                                        v-validate="'required'"
                                        required
                                    ></multiselect>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preferences')">{{ errors.first('job_preferences') }}</span>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="createOrEditGroupButtonLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledSaveButtonCreateOrEditGroup ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledSaveButtonCreateOrEditGroup"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit community modal box -->

                <!-- Delete community modal box -->
                <div id="delete-group-modal-box" class="uk-flex-top delete-group-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedGroupData" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Are you sure to delete this data ?</h2>
                        </div>
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p>Name: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ selectedGroupData.name || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p>Description: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ selectedGroupData.description || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p>Admin: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ selectedGroupData.admin_data && selectedGroupData.admin_data.fullname ? selectedGroupData.admin_data.fullname : '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p>Logo: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <img
                                                v-if="selectedGroupData.logo !== null" 
                                                :src="`${selectedGroupData.logo}`"
                                                alt="Community banner"
                                                class="uk-width-1-1" 
                                            >
                                            <p v-else>-</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p>Banner Image: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <img
                                                v-if="selectedGroupData.banner_image !== null" 
                                                :src="`${selectedGroupData.banner_image}`"
                                                alt="Community banner"
                                                class="uk-width-1-1" 
                                            >
                                            <p v-else>-</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="deleteGroupButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button uk-border-rounded"
                                :class="[disabledButtonDeleteGroup ? 'uk-button-default' : 'uk-button-danger']"
                                :disabled="disabledButtonDeleteGroup"
                                @click="doDeleteGroup"
                            >Delete</button>
                        </div>
                    </div>
                </div>
                <!-- End delete community modal box -->
            </template>
        </template>
        <no-internet-connection v-else></no-internet-connection>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'Community',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection')
    },
    data() {
        return {
            nameLength: 60,
            internetConnection: true,
            user_cred: getUserLogin(),
            images: PREFIX_IMAGE,
            isLoading: false,
            tableLoading: false,
            createOrEditGroupButtonLoading: false,
            deleteGroupButtonLoading: false,
            actionType: 'create',
            adminOptions: [],
            jobPreferenceOptions: [],
            group: {
                docs: [],
                totalDocs: 0
            },
            tempFileAttachmentLogo: {
                url: null,
                file: null
            },
            tempFileAttachmentBanner: {
                url: null,
                file: null
            },
            fileAttachmentLogo: {
                url: null,
                file: null
            },
            fileAttachmentBanner: {
                url: null,
                file: null
            },
            selectedGroupData: {
                id: null,
                name: '',
                description: '',
                logo: null,
                banner_image: null,
                admin_data: null,
                job_preference_data: null
            },
            meta: {
                limit: 10,
                page: 1,
                name: ''
            }
        };
    },
    watch: {
        async meta() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.tableLoading = true;
                    await this.setGroup();
                } else {    
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        // 'selectedGroupData.job_preference_data'(val) {
        //     console.log(val);
        // }
    },
    computed: {
        disabledSaveButtonCreateOrEditGroup() {
            let disabled = true;

            if (this.actionType === 'create') {
                disabled = this.selectedGroupData.name === '' ||
                    this.selectedGroupData.admin_data === null ||
                    this.selectedGroupData.description === '' ||
                    this.selectedGroupData.job_preference_data === null || (this.selectedGroupData.job_preference_data.length === 0) ||
                    this.$validator.errors.any();
            } else if (this.actionType === 'edit') {
                disabled = this.selectedGroupData.id === null ||
                    this.selectedGroupData.admin_data === null ||
                    this.selectedGroupData.name === '' ||
                    this.selectedGroupData.description === '' ||
                    this.selectedGroupData.job_preference_data === null || (this.selectedGroupData.job_preference_data.length === 0) ||
                    this.$validator.errors.any();
            } else {
                disabled = true;
            }

            return disabled;
        },
        disabledButtonDeleteGroup() {
            let disabled = true;
            if (this.selectedGroupData.id === null) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-group-modal-box')) window.UIkit.modal('.create-or-edit-group-modal-box').$destroy(true);
        if (window.UIkit.modal('.delete-group-modal-box')) window.UIkit.modal('.delete-group-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await this.setGroup();
                await this.setAdminOptions();
                await this.setJobPreferenceOptions();
            } else {
                this.internetConnection = false;
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getGroup: 'community/getGroup',
            showGroup: 'community/showGroup',
            deleteGroup: 'community/deleteGroup',
            createGroup: 'community/createGroup',
            updateGroup: 'community/updateGroup',
            listsUser: 'user/ListsUser',
            uploadImageApi: 'file/uploadImage',
            getAllJobPreferenceList: 'job_preference/getAllJobPreferenceList',
        }),
        async setGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.group = await this.getGroup(this.meta);
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setAdminOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.adminOptions = await this.listsUser({ roles: 'administrator', scope:'all' });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setJobPreferenceOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.jobPreferenceOptions = await this.getAllJobPreferenceList();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.tableLoading = true;
                    await this.setGroup();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async showCreateOrEditGroupModalBox({ type, id, name, description, logo, bannerImage, adminData, jobPreferenceData }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = type;
                    this.resetSelectedGroupData();
                    this.resetTempFileAttachmentLogo();
                    this.resetTempFileAttachmentBanner();
                    this.resetFileAttachmentLogo();
                    this.resetFileAttachmentBanner();
                    this.errors.clear();
                    if (type === 'edit') {
                        this.fillSelectedGroupData({ id, name, description, logo, bannerImage, adminData, jobPreferenceData });
                    }
                    await window.UIkit.modal('#create-or-edit-group-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDeleteGroupModalBox({ id, name, description, logo, bannerImage, adminData, jobPreferenceData }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.resetSelectedGroupData();
                    this.resetTempFileAttachmentLogo();
                    this.resetTempFileAttachmentBanner();
                    this.resetFileAttachmentLogo();
                    this.resetFileAttachmentBanner();
                    this.fillSelectedGroupData({ id, name, description, logo, bannerImage, adminData, jobPreferenceData });
                    await window.UIkit.modal('#delete-group-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createOrEditGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.actionType === 'create') {
                        await this.doCreateNewGroup();
                    } else if (this.actionType === 'edit') {
                        await this.doEditGroup();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        triggerUploadGroupLogo() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$refs.logo.click();
            } else {
                this.internetConnection = false;
            }
        },
        triggerUploadGroupBanner() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$refs.banner.click();
            } else {
                this.internetConnection = false;
            }
        },
        previewGroupLogo({ event }) {
            if (event.target.files && event.target.files.length > 0) {
                this.fileAttachmentLogo.url = URL.createObjectURL(event.target.files[0]);
                this.fileAttachmentLogo.file = event.target.files[0];
            }
        },
        previewGroupBanner({ event }) {
            if (event.target.files && event.target.files.length > 0) {
                this.fileAttachmentBanner.url = URL.createObjectURL(event.target.files[0]);
                this.fileAttachmentBanner.file = event.target.files[0];
            }
        },
        async uploadGroupLogo() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const formData = new FormData();
                    formData.append("file", this.fileAttachmentLogo.file);
                    const response = await this.uploadImageApi({ folder: 'group-logo', category: 'group', data: formData });
                    if (response && response.file_url) {
                        this.selectedGroupData.logo = response.file_url;
                    } else {
                        this.selectedGroupData.logo = null;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                this.selectedGroupData.logo = null;
                notificationDanger(error);
            }
        },
        async uploadGroupBanner() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const formData = new FormData();
                    formData.append("file", this.fileAttachmentBanner.file);
                    const response = await this.uploadImageApi({ folder: 'group-banner', category: 'group', data: formData });
                    if (response && response.file_url) {
                        this.selectedGroupData.banner_image = response.file_url;
                    } else {
                        this.selectedGroupData.banner_image = null;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                this.selectedGroupData.banner_image = null;
                notificationDanger(error);
            }
        },
        groupDetail({ groupId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`community/member/${groupId}`);
            } else {
                this.internetConnection = false;
            }
        },
        groupTopic({ groupId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`/admin/community/community/topic/${groupId}`);
            } else {
                this.internetConnection = false;
            }
        },
        async doCreateNewGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.createOrEditGroupButtonLoading = true;

                    if (this.fileAttachmentLogo.url && this.fileAttachmentLogo.file) {
                        await this.uploadGroupLogo();
                    } else {
                        this.selectedGroupData.logo = null;
                    }

                    if (this.fileAttachmentBanner.url && this.fileAttachmentBanner.file) {
                        await this.uploadGroupBanner();
                    } else {
                        this.selectedGroupData.banner_image = null;
                    }

                    let preference_ids = null;
                    if (this.selectedGroupData.job_preference_data && this.selectedGroupData.job_preference_data.length > 0) {
                        preference_ids = this.selectedGroupData.job_preference_data.map((data) => data._id);
                    } else {
                        preference_ids = null;
                    }

                    const payloads = {
                        name: this.selectedGroupData.name,
                        description: this.selectedGroupData.description,
                        admin_id: this.selectedGroupData.admin_data && this.selectedGroupData.admin_data._id ? this.selectedGroupData.admin_data._id : null,
                        logo: this.selectedGroupData.logo,
                        banner_image: this.selectedGroupData.banner_image,
                        job_preference_ids: preference_ids
                    };

                    const response = await this.createGroup(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-group-modal-box').hide();
                        this.resetSelectedGroupData();
                        this.resetTempFileAttachmentLogo();
                        this.resetTempFileAttachmentBanner();
                        this.resetFileAttachmentLogo();
                        this.resetFileAttachmentBanner();
                        this.tableLoading = true;
                        await this.setGroup();
                        this.tableLoading = false;
                        this.errors.clear();
                        this.$validator.reset();
                        notificationSuccess('Group created successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditGroupButtonLoading = false;
                this.tableLoading = false;
            }
        },      
        async doEditGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.createOrEditGroupButtonLoading = true;

                    if (this.fileAttachmentLogo.url && this.fileAttachmentLogo.file) {
                        if (
                            this.fileAttachmentLogo.url !== this.tempFileAttachmentLogo.url &&
                            this.fileAttachmentLogo.file !== this.tempFileAttachmentLogo.file
                        ) {
                            await this.uploadGroupLogo();
                        }
                    } else {
                        this.selectedGroupData.logo = null;
                    }

                    if (this.fileAttachmentBanner.url && this.fileAttachmentBanner.file) {
                        if (
                            this.fileAttachmentBanner.url !== this.tempFileAttachmentBanner.url && 
                            this.fileAttachmentBanner.file !== this.tempFileAttachmentBanner.file
                        ) {
                            await this.uploadGroupBanner();
                        }
                    } else {
                        this.selectedGroupData.banner_image = null;
                    }

                    let preference_ids = null;
                    if (this.selectedGroupData.job_preference_data && this.selectedGroupData.job_preference_data.length > 0) {
                        preference_ids = this.selectedGroupData.job_preference_data.map((data) => data._id);
                    } else {
                        preference_ids = null;
                    }

                    const payloads = {
                        id: this.selectedGroupData.id,
                        name: this.selectedGroupData.name,
                        description: this.selectedGroupData.description,
                        admin_id: this.selectedGroupData.admin_data && this.selectedGroupData.admin_data._id ? this.selectedGroupData.admin_data._id : null,
                        logo: this.selectedGroupData.logo,
                        banner_image: this.selectedGroupData.banner_image,
                        job_preference_ids: preference_ids
                    };

                    const response = await this.updateGroup(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-group-modal-box').hide();
                        this.resetSelectedGroupData();
                        this.resetFileAttachmentLogo();
                        this.resetFileAttachmentBanner();
                        this.tableLoading = true;
                        await this.setGroup();
                        this.tableLoading = false;
                        this.errors.clear();
                        this.$validator.reset();
                        notificationSuccess('Group updated successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditGroupButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async doDeleteGroup() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.deleteGroupButtonLoading = true;

                    const response = await this.deleteGroup(this.selectedGroupData.id);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#delete-group-modal-box').hide();
                        this.resetSelectedGroupData();
                        this.tableLoading = true;
                        await this.setGroup();
                        this.tableLoading = false;
                        notificationSuccess('Group deleted successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.deleteGroupButtonLoading = false;
                this.tableLoading = false;
            }
        },
        fillSelectedGroupData({ id, name, description, logo, bannerImage, adminData, jobPreferenceData }) {
            this.selectedGroupData.id = id;
            this.selectedGroupData.name = name;
            this.selectedGroupData.description = description;
            this.selectedGroupData.logo = logo;
            this.selectedGroupData.banner_image = bannerImage;
            this.selectedGroupData.admin_data = adminData;
            this.selectedGroupData.job_preference_data = jobPreferenceData;

            if (logo) {
                this.tempFileAttachmentLogo.url = logo;
                this.tempFileAttachmentLogo.file = logo;
                this.fileAttachmentLogo.url = logo;
                this.fileAttachmentLogo.file = logo;
            }

            if (bannerImage) {
                this.tempFileAttachmentBanner.url = bannerImage;
                this.tempFileAttachmentBanner.file = bannerImage;
                this.fileAttachmentBanner.url = bannerImage;
                this.fileAttachmentBanner.file = bannerImage;
            }
        },
        resetSelectedGroupData() {
            this.selectedGroupData.id = null;
            this.selectedGroupData.name = '';
            this.selectedGroupData.description = '';
            this.selectedGroupData.logo = null;
            this.selectedGroupData.banner_image = null;
            this.selectedGroupData.admin_data = null;
            this.selectedGroupData.job_preference_data = null;
            this.errors.clear();
            this.$validator.reset();
        },
        resetTempFileAttachmentLogo() {
            this.tempFileAttachmentLogo.url = null;
            this.tempFileAttachmentLogo.file = null;
            this.errors.clear();
        },
        resetTempFileAttachmentBanner() {
            this.tempFileAttachmentBanner.url = null;
            this.tempFileAttachmentBanner.file = null;
            this.errors.clear();
        },
        resetFileAttachmentLogo() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.fileAttachmentLogo.url = null;
                this.fileAttachmentLogo.file = null;
                this.errors.clear();
            } else {
                this.internetConnection = false;
            }
        },
        resetFileAttachmentBanner() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.fileAttachmentBanner.url = null;
                this.fileAttachmentBanner.file = null;
                this.errors.clear();
            } else {
                this.internetConnection = false;
            }
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        preview({ string }) {
            if (string == null) {
                return '-';
            } else {
                if (string.length > 26) {
                    return string.substring(0,26)+'...';
                } else {
                    return string;
                }
            }
        },
        dataExist({ data }) {
            if (!data) {
                return false;
            } else if (data.length < 1) {
                return false;
            } else {
                return true;
            }
        },
        customLabelJobPreferenceOptions({ name, category_name }) {
            let label = null;

            if (name && category_name) {
                label = `${name} - ${category_name}`;
            }

            return label;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        }
    }
};
</script>

<style scoped>
.icon-close-button {
    background: white;
    border: 1px solid black;
    outline: none;
    border-radius: 100%;
    cursor: pointer;
    color: black;
    padding: 10px;
    margin: 10px;
    box-shadow: 1px;
}
.icon {
    width: 8px;
    height: auto;
}
</style>
