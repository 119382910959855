<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search coupon">
                </div>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('mainModal', null, false)">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-right">
                            <th class="uk-text-center">Voucher Name</th>
                            <th class="uk-text-center">Brand</th>
                            <th class="uk-text-center">Branch</th>
                            <th class="uk-text-center">Start Date</th>
                            <th class="uk-text-center">End Date</th>
                            <th class="uk-text-center">Voucher<br>Amount</th>
                            <th class="uk-text-center">Claimed<br>Voucher</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="8"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{preview(data.name)}}</td>
                            <td>{{preview(data.brand)}}</td>
                            <td>{{preview(data.branch)}}</td>
                            <td>{{preview(moment(data.start_date).format('DD MMM YYYY'))}}</td>
                            <td>{{preview(moment(data.end_date).format('DD MMM YYYY'))}}</td>
                            <td>{{preview(data.coupon_amount)}}</td>
                            <td>{{preview(data.coupon_claimed)}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                                            <li><a @click="showModal('generateModal', data, true)">Generate</a></li>
                                            <li class="uk-nav-divider"></li>
                                        </template>
                                        <li><a @click="detailCoupon(data._id)">Detail</a></li>
                                        <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="8"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <!-- Modal Create-->
        <div id="mainModal" class="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
                <div class="uk-modal-header">
                    <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
                    <h2 v-else class="uk-modal-title">Create New Coupon</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Coupon Name <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('Coupon Name')}"
                            name="Coupon Name"
                            type="text"
                            v-model="form.name"
                            placeholder="Type coupon name"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Coupon Name')">{{ errors.first('Coupon Name') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Brand <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('Brand Name')}"
                            name="Brand Name"
                            type="text"
                            v-model="form.brand"
                            placeholder="Type brand name"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Brand Name')">{{ errors.first('Brand Name') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Brand Logo</label><br>
                        <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="openFileLogo">
                            <span class="uk-margin-small-right uk-icon" uk-icon="cloud-upload"></span>{{ logo_name }}
                        </button>
                        <input
                            name="brand logo"
                            type="file"
                            ref="logo"
                            style="display: none"
                            placeholder="Upload Logo"
                            @change="uploadLogo($event)"
                        >
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Branch <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('Branch')}"
                            name="Branch"
                            type="text"
                            v-model="form.branch"
                            placeholder="Type branch name"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Branch')">{{ errors.first('Branch') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Related Group <b class="uk-text-danger">*</b></label>
                        <multiselect
                            v-model="form.group_id"
                            name="Related Group"
                            label="name"
                            track-by="name"
                            placeholder="Select related group"
                            :options="group_id"
                            v-validate="'required'"
                        ></multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Related Group')">{{ errors.first('Related Group') }}</span>
                    </div>
                    <div class="uk-margin" uk-grid>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Amount <b class="uk-text-danger">*</b></label>
                            <input
                                class="uk-input"
                                :class="{'uk-form-danger': errors.has('Amount')}"
                                name="Amount"
                                type="number"
                                placeholder="Type amount"
                                v-model="form.coupon_amount"
                                v-validate="'required'"
                                min=1
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('Amount')">{{ errors.first('Amount') }}</span>
                        </div>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Voucher Value</label>
                            <input
                                class="uk-input"
                                name="value"
                                type="number"
                                placeholder="Type Voucher Value"
                                v-model="form.coupon_value"
                                min=1
                            >
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Start Date <b class="uk-text-danger">*</b></label><br>
                        <div class="uk-inline uk-width-1-1 uk-margin-remove">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datetime
                                input-class="uk-input"
                                type="date"
                                :class="{'uk-form-danger': errors.has('Start Date')}"
                                name="Start Date"
                                v-model="form.start_date"
                                :min-datetime="new Date().toISOString()"
                                format="dd MMM yyyy"
                                placeholder="13 Jan 2022"
                                v-validate="'required'"
                            ></datetime>
                        </div>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Start Date')">{{ errors.first('Start Date') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">End Date <b class="uk-text-danger">*</b></label><br>
                        <div class="uk-inline uk-width-1-1 uk-margin-remove">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datetime
                                input-class="uk-input"
                                type="date"
                                :class="{'uk-form-danger': errors.has('End Date')}"
                                name="End Date"
                                v-model="form.end_date"
                                :min-datetime="form.start_date"
                                format="dd MMM yyyy"
                                placeholder="16 Jan 2021"
                                v-validate="'required'"
                            ></datetime>
                        </div>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('End Date')">{{ errors.first('End Date') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Voucher Type <b class="uk-text-danger">*</b></label>
                        <select
                            class="uk-select"
                            :class="{'uk-form-danger': errors.has('type')}"
                            v-model="form.type"
                            v-validate="'required'"
                            :disabled="isEdit"
                        >
                            <option value=2>With unique code</option>
                            <option value=1>Without unique code</option>
                        </select>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('type')">{{ errors.first('type') }}</span>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
                </div>
            </div>
        </div>

        <div id="deleteModal" class="uk-flex-top deleteModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header uk-text-large"><b>Are you sure to delete this coupon?</b></div>
                <div class="uk-modal-body" >
                    <span>Name: <span class="uk-text-bolder">{{ form.name }}</span></span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>

        <div id="generateModal" class="modal-close-default uk-flex-top generateModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body">
                    <div class="container">
                        <h3 class="uk-text-center uk-text-bolder">{{ form.name }}</h3>
                        <p class="uk-text-center">in {{ form.brand }}, {{ form.branch }}</p>
                        <div class="child uk-border-rounded">
                            <vue-qrcode
                                style="border-radius: 5px;"
                                ref="qr"
                                :value="'coupon:' + form._id"
                                :options='{ width: 240, color: { dark: "#000000", light: "#FFFFFF"} }'
                            ></vue-qrcode>
                            <p class="uk-text-center" style="font-size: 15px">
                                <strong>{{ `Valid ${moment(form.start_date).format('DD-MM-YYYY')} to ${moment(form.end_date).format('DD-MM-YYYY')}` }}</strong>
                            </p>
                        </div>
                        <div class="uk-text-center uk-margin-medium-top">
                            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="createPDF(form)">
                                <img :src="`${images}/download.svg`" alt="" width="20" class="uk-margin-small-right">{{ 'Download QR Code (.pdf)' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import { Datetime } from 'vue-datetime';
import moment from 'moment';
moment.locale('id');
import jsPDF from 'jspdf';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            isOpen: false,
            group_id: [],
            user_cred: getUserLogin(),
            moment: moment,
            images: PREFIX_IMAGE,
            logo_name: 'Upload Logo',
            meta: {
                limit: 50,
                page: 1,
                name: '',
                category: ''
            },
            form: {
                name: '',
                group_id: '',
                brand: '',
                branch: '',
                coupon_amount: '',
                start_date: '',
                end_date: '',
                logo: '',
                type: 1,
                coupon_value: null
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false,
            group: []
        };
    },
    components: {
        Datetime,
        EmptyTable,
        Pagination,
        LoadingTable,
        VueQrcode: () => import('@chenfengyuan/vue-qrcode')
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.deleteModal')) window.UIkit.modal('.deleteModal').$destroy(true);
        if(window.UIkit.modal('.mainModal')) window.UIkit.modal('.mainModal').$destroy(true);
        if(window.UIkit.modal('.generateModal')) window.UIkit.modal('.generateModal').$destroy(true);
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getCoupon: 'community/getCoupon',
            showCoupon: 'community/showCoupon',
            deleteCoupon: 'community/deleteCoupon',
            createCoupon: 'community/createCoupon',
            updateCoupon: 'community/updateCoupon',
            listGroup: 'community/listGroup',
            uploadImage: 'file/uploadImage',
        }),
        createPDF (item) {
            const doc = new jsPDF();
            const contentHtml = this.$refs.qr.$el;
            const image = contentHtml.toDataURL('image/jpeg', 0.8);
            doc.setFillColor(12, 147, 137);
            doc.roundedRect(69, 45, 75, 90, 2, 2, 'F');
            doc.addImage(image, 'JPEG', 75, 50);
            //
            doc.setFontSize(15);
            doc.setFont(undefined, 'bold');
            doc.text(item.name, 107,25, 'center');
            //
            doc.setTextColor(102, 102, 102);
            doc.setFontSize(12);
            doc.setFont(undefined, 'normal');
            doc.text(`in ${ item.brand }, ${ item.branch }`, 107,35, 'center');
            //
            doc.setFontSize(13);
            doc.setTextColor(255, 255, 255);
            doc.text(`Valid ${ moment(item.start_date).format('DD-MM-YYYY') } to ${ moment(item.end_date).format('DD-MM-YYYY') }`, 107, 125, { align: 'center', maxWidth: 85 });
            //
            doc.save('coupon:' + item._id + '.pdf');
        },
        detailCoupon(id) {
            this.$router.push('/admin/community/coupon/' + id);
        },
        openFileLogo() {
            this.$refs.logo.click();
        },
        async uploadLogo() {
            this.logo_name = event.target.files[0].name;
            let files = this.$refs.logo.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImage({ folder: 'coupon-logo', category: 'coupon', data: formData });
                this.form.logo = result.file_path;
            }
        },
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getCoupon(this.meta);
            this.group_id = await this.$store.dispatch('community/listGroup');
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        resetForm() {
            this.form = {
                name: '',
                group_id: '',
                brand: '',
                branch: '',
                coupon_amount: '',
                start_date: '',
                end_date: '',
                logo: '',
                type: 1,
                coupon_value: null
            };
            this.isEdit = false;
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.isOpen = false;
            switch (modal) {
            case 'mainModal':
                this.resetForm();
                if (data) {
                    this.form.name = data.name;
                    this.form.branch = data.branch;
                    this.form.brand = data.brand;
                    this.form.coupon_amount = data.coupon_amount;
                    this.form.start_date = data.start_date;
                    this.form.end_date = data.end_date;
                    this.form.group_id = data.group._id;
                    this.form.type = data.type;
                    this.form.coupon_value = data.coupon_value;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.form.name = data.name;
                    this.form.branch = data.branch;
                    this.form.brand = data.brand;
                    this.form.coupon_amount = data.coupon_amount;
                    this.form.group_id = data.group;
                    this.form.start_date = data.start_date;
                    this.form.end_date = data.end_date;
                    this.isEdit = true;
                    this.form.type = data.type;
                    this.form.coupon_value = data.coupon_value;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            case 'generateModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#generateModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;
                this.form.logo ? null : delete this.form.logo;
                this.form.coupon_value = this.form.coupon_value == "" ? null : this.form.coupon_value;
                const response = this.isEdit
                    ? await this.updateCoupon(this.form)
                    : await this.createCoupon(this.form);
                this.isLoading = false;

                if (response) {
                    notificationSuccess('Coupon Saved!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;
                const response = await this.deleteCoupon(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>

<style scoped>
    .qr-code {
        height: auto;
        width: 50rem;
    }
    .container {
        margin: auto;
        width: 400px;
        height: auto;
        padding: 20px;
    }
    .container button {
        width: 60%;
        font-size: 12px;
    }
    .child {
        width: 220px;
        height: auto;
        /*outline: dashed 1px black;*/
        /* Center horizontally*/
        margin: 0 auto;
        padding: 10px 10px;
        background: #0C9389;
    }
    .child p {
        color: white;
        font-size: 12px;
    }
</style>
