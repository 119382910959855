<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div v-if="!isLoading" class="uk-flex uk-flex-between uk-margin">
            <div class="uk-width-expand uk-flex">
                <div class="uk-width-auto uk-margin-auto uk-margin-auto-vertical">
                    <div>
                        <img
                            v-if="page_data.coupon"
                            :src="base_path + (page_data.coupon.logo_url ? page_data.coupon.logo_url.file_path : `${images}/avatar.svg`)"
                            @error="(e) => imgError(e)" class="uk-border-pill" width="150" height="150"
                        >
                        <img v-else :src="`${images}/avatar.svg`" class="photo">
                        <!-- <img :src="`${images}/avatar.svg`" class="photo"> -->
                    </div>
                </div>
                <div class="uk-width-expand uk-margin-auto-vertical uk-margin-left">
                    <div class="applicant_name">
                        <h4><span class="uk-text-bold">{{ page_data.coupon ? page_data.coupon.name : 'Nihil' }}</span></h4>
                    </div>
                    <div class="uk-flex uk-flex-col">
                        <div class="uk-width-auto">
                            <span>Brand: {{ page_data.coupon ? page_data.coupon.brand : 'Nihil' }}</span><br>
                            <span>Branch: {{ page_data.coupon ? page_data.coupon.branch : 'Nihil' }}</span><br>
                        </div>
                        <div class="uk-width-auto uk-margin-left">
                            <span>Voucher Value: {{ page_data.coupon ? page_data.coupon.coupon_value ? page_data.coupon.coupon_value : 'Nihil' : 'Nihil' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto" v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                <div class="uk-flex uk-flex-row" style="padding-top: 1rem;">
                    <div class="uk-flex uk-flex-column uk-margin-right">
                        <button class="uk-button robin-green uk-border-rounded" @click="showModal('generateModal')" type="button" 
                        :disabled="!page_data.coupon">View Coupon</button>
                        <button v-if="page_data.coupon && page_data.coupon.type == 2" class="uk-button robin-orange uk-border-rounded uk-margin-small-top" type="button" 
                        :disabled="isLoadingButton" :uk-spinner="isLoadingButton" @click="downloadTemplateExcel"><span v-if="!isLoadingButton">Download Template</span></button>
                    </div>
                    <div class="uk-flex uk-flex-column uk-margin-right">
                        <button class="uk-button uk-button-primary uk-border-rounded" type="button" 
                        :disabled="page_data.docs.length < 1" @click="downloadExcel">Download Report</button>
                        <button 
                            v-if="page_data.coupon && page_data.coupon.type == 2"
                            type="button"
                            class="uk-button robin-orange-outlined uk-border-rounded uk-margin-small-top"
                            @click="triggerUpload"
                        >
                            <span>Upload Template</span>
                            <input
                                ref="upload"
                                style="display: none"
                                type="file"
                                accept=".xls,.xlsx"
                                @change="uploadTemplate"
                            />
                        </button>
                    </div>
                    <div class="uk-width-auto uk-flex-column">
                        <button @click="$router.back()" class="uk-close-large" style="color: black;" type="button" uk-close></button> 
                    </div>
                </div>
            </div>
        </div>
        <div><h3>Statistics</h3></div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th v-if="page_data.coupon && page_data.coupon.type == 2">Unique Code</th>
                            <th>User Name</th>
                            <th>Tel. Number</th>
                            <th>Email</th>
                            <th>Claim Date</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="4"/>
                    <tbody v-else-if="page_data.coupon && page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <th v-if="page_data.coupon && page_data.coupon.type == 2">{{preview(data.unique_code)}}</th>
                            <td>{{preview(data.user ? data.user.username : null)}}</td>
                            <td>{{preview(data.user ? data.user.phone_number : null)}}</td>
                            <td>{{preview(data.user ? data.user.email : null)}}</td>
                            <td>{{preview(data.user ? page_data.coupon.type == 2 ? moment(data.updated_at).format('DD MMM YYYY') : moment(data.created_at).format('DD MMM YYYY') : null)}}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="page_data.coupon ? page_data.coupon.type == 2 ? 5 : 4 : 4"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <!-- Modal Create-->
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
                <div class="uk-modal-header">
                    <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
                    <h2 v-else class="uk-modal-title">Create New Coupon</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Coupon Name <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('name')}"
                            name="name"
                            type="text"
                            v-model="form.name"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Brand <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('url')}"
                            name="url"
                            type="text"
                            v-model="form.brand"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('url')">{{ errors.first('url') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Brand Logo <b class="uk-text-danger">*</b></label><br>
                        <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="openFileLogo">
                            <span class="uk-margin-small-right uk-icon" uk-icon="cloud-upload"></span>{{ logo_name }}
                        </button>
                        <input
                            name="image"
                            type="file"
                            ref="logo"
                            style="display: none"
                            placeholder="Upload Image"
                            v-validate="'required'"
                            @change="uploadLogo($event)"
                        >
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Branch <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('url')}"
                            name="url"
                            type="text"
                            v-model="form.branch"
                            v-validate="'required'"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('url')">{{ errors.first('url') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Related Group <b class="uk-text-danger">*</b></label>
                        <multiselect
                            v-model="form.group"
                            placeholder="Select Category"
                            name="category"
                            :options="group.map(type => type.id)"
                            :custom-label="opt => group.find(x => x.id == opt).name"
                            v-validate="'required'"
                        ></multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('category')">{{ errors.first('category') }}</span>
                    </div>
                    <div class="uk-margin" uk-grid>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Amount <b class="uk-text-danger">*</b></label>
                            <input
                                class="uk-input"
                                :class="{'uk-form-danger': errors.has('url')}"
                                name="url"
                                type="text"
                                v-model="form.coupon_amount"
                                v-validate="'required'"
                            >
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('url')">{{ errors.first('url') }}</span>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Start Date <b class="uk-text-danger">*</b></label><br>
                        <div class="uk-inline uk-width-1-1 uk-margin-remove">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datetime
                                input-class="uk-input"
                                type="date"
                                :class="{'uk-form-danger': errors.has('birthdate')}"
                                name="birthdate"
                                v-model="form.start_date"
                                placeholder="dd/mm/yy"
                                v-validate="'required'"
                            ></datetime>
                        </div>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('birthdate')">{{ errors.first('birthdate') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">End Date <b class="uk-text-danger">*</b></label><br>
                        <div class="uk-inline uk-width-1-1 uk-margin-remove">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datetime
                                input-class="uk-input"
                                type="date"
                                :class="{'uk-form-danger': errors.has('birthdate')}"
                                name="birthdate"
                                v-model="form.end_date"
                                placeholder="dd/mm/yy"
                                v-validate="'required'"
                            ></datetime>
                        </div>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('birthdate')">{{ errors.first('birthdate') }}</span>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
                </div>
            </div>
        </div>

        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data?</b></div>
                <div class="uk-modal-body">
                    <span>Name: {{form.name}}</span><br>
                    <span>Category: {{form.category}}</span><br>
                    <span>URL: {{form.url}}</span>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>

        <div id="generateModal" class="modal-close-default uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div v-if="page_data.coupon" class="uk-modal-body">
                    <div class="container">
                        <h3 class="uk-text-center uk-text-bolder">{{ page_data.coupon.name }}</h3>
                        <p class="uk-text-center">in {{ page_data.coupon.brand }}, {{ page_data.coupon.branch }}</p>
                        <div class="child uk-border-rounded">
                            <vue-qrcode
                                style="border-radius: 5px;"
                                ref="qr"
                                :value="'coupon:' + page_data.coupon._id"
                                :options='{ width: 240, color: { dark: "#000000", light: "#FFFFFF"} }'
                            ></vue-qrcode>
                            <p class="uk-text-center" style="font-size: 15px">
                                <strong>{{ `Valid ${moment(page_data.coupon.start_date).format('DD-MM-YYYY')} to ${moment(page_data.coupon.end_date).format('DD-MM-YYYY')}` }}</strong>
                            </p>
                        </div>
                        <div class="uk-text-center uk-margin-medium-top">
                            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="createPDF(page_data.coupon._id)">
                                <img :src="`${images}/download.svg`" alt="" width="20" class="uk-margin-small-right">{{ 'Download QR Code (.pdf)' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import { Datetime } from 'vue-datetime';
import moment from 'moment';
moment.locale('id');
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import jsPDF from 'jspdf';
import { getUserLogin } from '@/utils/auth';
import { excelDownloader } from '@/utils/helper';
import * as XLSX from "xlsx";

export default {
    data({ $route }) {
        return {
            user_cred: getUserLogin(),
            moment: moment,
            images: PREFIX_IMAGE,
            base_path: 'https://s3.ap-southeast-1.amazonaws.com/documents.myrobin.tech/coupon-logo/',
            logo_name: 'Upload Logo',
            meta: {
                limit: 50,
                page: 1,
                name: '',
                coupon_id: $route.params.coupon_id
            },
            form: {
                name: '',
                group: '',
                brand: '',
                branch: '',
                coupon_amount: '',
                start_date: '',
                end_date: '',
                url: ''
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isLoadingButton: false,
            isEdit: false,
            group: []
        };
    },
    components: {
        Datetime,
        EmptyTable,
        Pagination,
        LoadingTable,
        VueQrcode: () => import('@chenfengyuan/vue-qrcode')
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            showCoupon: 'community/showCoupon',
            deleteCoupon: 'community/deleteCoupon',
            createCoupon: 'community/createCoupon',
            updateCoupon: 'marketing/updateCoupon',
            listCoupon: 'community/listCoupon',
            downloadTemplateVoucher: 'community/downloadTemplateVoucher',
            uploadTemplateVoucher: 'community/uploadTemplateVoucher',
            uploadImage: 'file/uploadImage',
        }),
        downloadExcel () {
            const dataExcel = [];
            this.page_data.docs.forEach(el => {
                if (this.page_data.coupon.type == 2) {
                    if (el.user) {
                        const obj = {
                            "Nama Voucher": this.page_data.coupon.name,
                            "Brand": this.page_data.coupon.brand,
                            "Branch": this.page_data.coupon.branch,
                            "Username": el.user.username,
                            "Nomor Telephone": el.user.phone_number,
                            "Email": el.user.email,
                            "Unique Code": el.unique_code,
                            "Claim Date": el.updated_at,
                            "Total Voucher": 1,
                        };
                        dataExcel.push(obj);
                    }
                } else {
                    const obj = {
                        "Nama Voucher": this.page_data.coupon.name,
                        "Brand": this.page_data.coupon.brand,
                        "Branch": this.page_data.coupon.branch,
                        "Username": el.user.username,
                        "Nomor Telephone": el.user.phone_number,
                        "Email": el.user.email,
                        "Claim Date": el.created_at,
                        "Total Voucher": 1,
                    };
                    dataExcel.push(obj);
                }
            });

            excelDownloader(dataExcel, `Coupon_Report_${this.page_data.coupon._id}.xls`);
        },
        createPDF (id) {
            const doc = new jsPDF();
            const contentHtml = this.$refs.qr.$el;
            const image = contentHtml.toDataURL('image/jpeg', 0.8);
            doc.addImage(image, 'JPEG', 50, 50);
            doc.save('coupon:' + id + '.pdf');
        },
        imgError(e){
            e.target.src = `${this.images}/avatar.svg`;
        },
        openFileLogo() {
            this.$refs.logo.click();
        },
        async uploadLogo() {
            this.logo_name = event.target.files[0].name;
            let files = this.$refs.logo.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImage({ folder: 'coupon-logo', category: 'coupon', data: formData });
                this.form.logo = result.file_path;
            }
        },
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.showCoupon(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (!data || data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        resetForm() {
            this.form = {
                name: '',
                category: '',
                url: ''
            };
            this.isEdit = false;
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form.name = data.name;
                    this.form.category = data.category;
                    this.form.url = data.url;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            case 'generateModal':
                await window.UIkit.modal('#generateModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const response = this.isEdit
                    ? await this.updateCoupon(this.form)
                    : await this.createCoupon(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Coupon Saved!');
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteCommunity(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async downloadTemplateExcel() {
            this.isLoadingButton = true;

            const response = await this.downloadTemplateVoucher(this.$route.params.coupon_id);
            if (response && response.status === 'OK') {
                excelDownloader(response.result.data, response.result.filename);
                notificationSuccess('Downloaded!');
            } else {
                notificationDanger(response.message);
            }

            this.isLoadingButton = false;
        },
        triggerUpload() {
            this.$refs.upload.click();
        },
        async uploadTemplate(e) {
            try {
                this.isLoadingButton = true;
                const filepath = e.target.files[0];
                const isValid = await this.checkTotalData(filepath);

                if (!isValid) {
                    throw "Jumlah Voucher melebihi Voucher Amount silahkan check kembali";
                }

                let data = new FormData();
                data.append('id', this.$route.params.coupon_id);
                data.append('excel', filepath);
                const response = await this.uploadTemplateVoucher(data);
                if (response && response.status) {
                    if (response.status === 'OK') {
                        data.delete('id');
                        data.delete('excel');
                        e.target.value = null;
                        await this.getInitPageData();
                        notificationSuccess('Template uploaded successfully');
                    }
                } else {
                    notificationDanger('Failed to upload');
                }
            } catch (error) {
                if(error.search('Available')>=0) {
                    notificationDanger('Jumlah Voucher melebihi Voucher Amount silahkan check kembali');
                } else {
                    notificationDanger(error);
                }
            } finally {
                this.isLoadingButton = false;
            }
        },
        checkTotalData(buffer) {
            return new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(buffer);
                fileReader.onload = () => {
                    this.arrayBuffer = fileReader.result;
                    var data = new Uint8Array(this.arrayBuffer);
                    var arr = [];

                    for (var i = 0; i != data.length; ++i) {
                        arr[i] = String.fromCharCode(data[i]);
                    }
                    
                    var bstr = arr.join("");
                    var workbook = XLSX.read(bstr, { type: "binary" });
                    var first_sheet_name = workbook.SheetNames[0];
                    var worksheet = workbook.Sheets[first_sheet_name];
                    var totData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    
                    if (totData.length > this.page_data.coupon.coupon_amount) {
                        resolve(false);
                    }

                    resolve(true);
                };
            });
        }
    },
};
</script>

<style scoped>
    .robin-green {
        background: #0ABAB5;
        color: white;
    }
    .robin-orange {
        background: #EBB652;
        color: white;
    }
    .robin-orange-outlined {
        background: white;
        color: #EBB652;
        border: 1px solid #EBB652;
    }
    .qr-code {
        height: auto;
        width: 50rem;
    }
    .container {
        margin: auto;
        width: 400px;
        height: auto;
        padding: 20px;
    }
    .container button {
        width: 60%;
        font-size: 12px;
    }
    .child {
        width: 220px;
        height: auto;
        /*outline: dashed 1px black;*/
        /* Center horizontally*/
        margin: 0 auto;
        padding: 10px 10px;
        background: #0C9389;
    }

    .child p {
        color: white;
        font-size: 12px;
    }
</style>
