<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading" />
            <template v-else>
                <!-- Filter and create live content -->
                <div class="uk-flex uk-flex-middle uk-flex-between">
                    <form @submit.prevent="filterLiveStream" class="uk-margin-remove">
                        <div class="uk-inline">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                            <input 
                                class="uk-input" 
                                type="search" 
                                aria-label="Search content" 
                                placeholder="Search live content"
                                v-model="meta.title"
                            />
                        </div>
                    </form>
                    <button class="uk-button uk-button-primary uk-border-rounded" @click="showCreateOrEditLiveContentModalBox({ type: 'create', id: null })">Create</button>
                </div>
                <!-- End filter and create live content -->

                <!-- Live content list -->
                <div class="uk-margin-top uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-center uk-text-nowrap">Title</th>
                                    <th class="uk-text-center uk-text-nowrap">Status</th>
                                    <th class="uk-text-center uk-text-nowrap">Video Link (Youtube)</th>
                                    <th class="uk-text-center uk-text-middle uk-table-expand">
                                        <p class="uk-margin-remove">PIC Community</p> 
                                        <p class="uk-margin-remove">Live Content</p>
                                    </th>
                                    <th class="uk-text-center uk-text-nowrap">Date</th>
                                    <th class="uk-text-center uk-text-nowrap">Start Time</th>
                                    <th class="uk-text-center uk-text-nowrap">End Time</th>
                                    <th class="uk-text-center uk-text-nowrap uk-table-shrink">Applicant</th>
                                    <th class="uk-text-center uk-text-middle uk-table-shrink">
                                        <p class="uk-margin-remove">Login</p> 
                                        <p class="uk-margin-remove">Visitor</p>
                                    </th>
                                    <th class="uk-text-center uk-text-middle uk-table-shrink">
                                        <p class="uk-margin-remove">Unlogin</p> 
                                        <p class="uk-margin-remove">Visitor</p>
                                    </th>
                                    <th class="uk-text-center uk-text-nowrap uk-width-small">Actions</th>
                                </tr>
                            </thead>
                            <loading-table v-if="tableLoading" :colspan="11" />
                            <template v-else>
                                <tbody v-if="liveStreamData.totalDocs > 0">
                                    <tr v-for="(liveStream, index) in liveStreamData.docs" :key="index">
                                        <td class="uk-text-left uk-text-nowrap">
                                            {{ stringPreview({ 
                                                text: liveStream.title || '', 
                                                limit: 21 
                                            }) }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            <label-status type="liveStream" :status="liveStream.status" />
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ stringPreview({ 
                                                text: liveStream.video_url || '', 
                                                limit: 16 
                                            }) }}
                                        </td>
                                        <td class="uk-text-center uk-text-middle uk-table-expand">
                                            <template v-if="liveStream && liveStream.community_pic && liveStream.community_pic.length > 0">
                                                <p class="uk-margin-remove">
                                                    {{ arrayPreview({ 
                                                        data: liveStream.community_pic, 
                                                        limit: 2 
                                                    }) }}
                                                </p>
                                            </template>
                                            <p v-else class="uk-margin-remove">-</p>
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ dateComplete({ date: liveStream.date || null }) }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ hourMinuteFromObject({ data: liveStream.start_time }) }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ hourMinuteFromObject({ data: liveStream.end_time }) }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap uk-table-shrink">
                                            {{ liveStream.total_applicant || 0 }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap uk-table-shrink">
                                            {{ liveStream.total_login_visitor || 0 }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap uk-table-shrink">
                                            {{ liveStream.total_unlogin_visitor || 0 }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap">
                                            {{ liveStream.unlogin_visitor || 0 }}
                                        </td>
                                        <td class="uk-text-center uk-text-nowrap uk-width-small">
                                            <button class="uk-button uk-button-default uk-button-small">Actions</button>
                                            <div uk-dropdown="mode: click">
                                                <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                    <li v-if="liveStream.status != 3" @click="showConfirmationSwitchStatusLiveContentModalBox({ id: liveStream._id || null })">
                                                        <a v-if="liveStream.status == 1" disabled>Turn On LIVE</a>
                                                        <a v-else-if="liveStream.status == 2" disabled>Finish LIVE</a>
                                                    </li>
                                                    <li v-if="liveStream.status != 3" class="uk-nav-divider"></li>
                                                    <li @click="showCreateOrEditLiveContentModalBox({ type: 'edit', id: liveStream._id || null })"><a disabled>Edit</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li @click="showConfirmationDeleteLiveContentModalBox({ id: liveStream._id || null })"><a disabled>Delete</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li @click="goToLiveContentDetail({ id: liveStream._id || null })"><a disabled>Details</a></li>
                                                    <li v-if="liveStream.status == 3" class="uk-nav-divider"></li>
                                                    <li 
                                                        v-if="liveStream.status == 3" 
                                                        :disabled="downloadReportLoading" 
                                                        @click="downloadReport({ id: liveStream._id || null })"
                                                    ><a disabled>Download Report</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="11" />
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="liveStreamData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End live content list -->

                <!-- Create or edit live content modal box -->
                <div id="create-or-edit-live-content-modal-box" class="uk-flex-top create-or-edit-live-content-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">{{ modalBoxTitle }}</h2>
                        </div>
                        <form @submit.prevent="createOrEditLiveContent">
                            <div class="uk-modal-body">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label for="title" class="uk-form-label">Title <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <input 
                                                id="title" 
                                                type="text" 
                                                class="uk-input uk-border-rounded"
                                                maxlength="100"
                                                data-vv-name="title"
                                                data-vv-scope="selectedLiveContent"
                                                v-validate="{ 
                                                    required: true,
                                                    max: 100
                                                }"
                                                v-model="selectedLiveContent.title"
                                            />
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.title')">
                                                {{ errors.first('selectedLiveContent.title') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="videoLink" class="uk-form-label">Video Link (Youtube) <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <div class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded overflow-hidden" uk-grid>
                                                <span class="uk-width-auto padding-y-15px">https://www.youtube.com/embed/</span>
                                                <input 
                                                    id="videoLink"
                                                    class="uk-width-expand uk-input custom-input-youtube-url" 
                                                    type="text" 
                                                    data-vv-name="video_url"
                                                    data-vv-scope="selectedLiveContent"
                                                    v-validate="{ required: true }"
                                                    v-model="selectedLiveContent.video_url"
                                                />
                                            </div>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.video_url')">
                                                {{ errors.first('selectedLiveContent.video_url') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <label for="picCommunities" class="uk-form-label">PIC Community - Live Content <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <multiselect
                                                :multiple="true"
                                                :close-on-select="false"
                                                :loading="picAdminCommunitySearchLoading"
                                                :options="picCommunityOptions"
                                                track-by="_id"
                                                label="fullname"
                                                placeholder="Select PIC Community"
                                                data-vv-name="pic_community_ids"
                                                data-vv-scope="selectedLiveContent"
                                                v-validate="{ required: true }"
                                                v-model="selectedLiveContent.pic_community_ids"
                                                @search-change="filterPicCommunityByFullname"
                                            />
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.pic_community_ids')">
                                                {{ errors.first('selectedLiveContent.pic_community_ids') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-2">
                                        <label for="date" class="uk-form-label">Date <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <div class="uk-inline uk-width-1-1">
                                                <datetime
                                                    input-id="date"
                                                    input-class="uk-input uk-border-rounded"
                                                    type="date"
                                                    format="d MMM yyyy"
                                                    value-zone="local"
                                                    :min-datetime="minDateInput"
                                                    data-vv-name="date"
                                                    data-vv-scope="selectedLiveContent"
                                                    v-validate="{ required: true }"
                                                    v-model="selectedLiveContent.date"
                                                    @input="changeDate"
                                                />
                                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                            </div>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.date')">
                                                {{ errors.first('selectedLiveContent.date') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-2" />
                                    <div class="uk-width-1-2">
                                        <label for="startTime" class="uk-form-label">Start Time (WIB) <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <div class="uk-inline uk-width-1-1">
                                                <datetime
                                                    title="Start Time (WIB)"
                                                    input-id="startTime"
                                                    input-class="uk-input uk-border-rounded"
                                                    type="time"
                                                    :minute-step="15"
                                                    :min-datetime="minStartTime"
                                                    :max-datetime="maxStartTime"
                                                    :disabled="!selectedLiveContent.date"
                                                    data-vv-name="start_time"
                                                    data-vv-scope="selectedLiveContent"
                                                    v-validate="{ required: true }"
                                                    v-model="selectedLiveContent.start_time"
                                                    @input="changeStartTime"
                                                />
                                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                            </div>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.start_time')">
                                                {{ errors.first('selectedLiveContent.start_time') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-2">
                                        <label for="endTime" class="uk-form-label">End Time (WIB) <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5-px">
                                            <div class="uk-inline uk-width-1-1">
                                                <datetime
                                                    title="End Time (WIB)"
                                                    input-id="endTime"
                                                    input-class="uk-input uk-border-rounded"
                                                    type="time"
                                                    :minute-step="15"
                                                    :min-datetime="minEndTime"
                                                    :max-datetime="maxEndTime"
                                                    :disabled="!selectedLiveContent.start_time"
                                                    data-vv-name="end_time"
                                                    data-vv-scope="selectedLiveContent"
                                                    v-validate="{ required: true }"
                                                    v-model="selectedLiveContent.end_time"
                                                />
                                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                            </div>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.end_time')">
                                                {{ errors.first('selectedLiveContent.end_time') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <div class="uk-width-1-1 uk-grid-small" uk-grid>
                                            <div class="uk-width-1-4">
                                                <label for="type" class="uk-form-label">Type</label>
                                            </div>
                                            <div class="w-width-expand">
                                                <span>:</span>
                                                <label for="typeHiring" class="uk-margin-small-left">
                                                    <input 
                                                        id="typeHiring"
                                                        v-model="selectedLiveContent.is_hiring" 
                                                        :value="true" 
                                                        class="uk-radio" 
                                                        type="radio" 
                                                        name="typeHiring"
                                                    /> 
                                                    Hiring
                                                </label>
                                                <label for="typeNonHiring" class="uk-margin-small-left">
                                                    <input 
                                                        id="typeNonHiring"
                                                        v-model="selectedLiveContent.is_hiring" 
                                                        :value="false"
                                                        class="uk-radio" 
                                                        type="radio" 
                                                        name="typeNonHiring"
                                                    /> 
                                                    Non Hiring
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <div class="uk-width-1-1 uk-grid-small" uk-grid>
                                            <div class="uk-width-1-4">
                                                <label for="isMidnight" class="uk-form-label">Midnight</label>
                                            </div>
                                            <div class="uk-width-expand">
                                                <span>:</span>
                                                <label for="isMidnightNo" class="uk-margin-small-left">
                                                    <input 
                                                        id="isMidnightNo"
                                                        v-model="selectedLiveContent.is_midnight" 
                                                        :value="false" 
                                                        class="uk-radio" 
                                                        type="radio" 
                                                        name="isMidnightNo"
                                                    /> 
                                                    No
                                                </label>
                                                <label for="isMidnightYes" class="uk-margin-small-left">
                                                    <input 
                                                        id="isMidnightYes"
                                                        v-model="selectedLiveContent.is_midnight" 
                                                        :value="true" 
                                                        class="uk-radio" 
                                                        type="radio" 
                                                        name="isMidnightYes"
                                                    /> 
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selectedLiveContent.is_hiring" class="uk-width-1-1">
                                        <label for="chooseVacancy" class="uk-form-label">Choose Vacancy <span class="uk-text-danger">*</span></label>
                                        <div class="uk-grid-small margin-top-5-px" uk-grid>
                                            <div v-for="(vacancy, index) in selectedLiveContent.vacancies" :key="index" class="uk-width-1-1">
                                                <div class="border-grey uk-border-rounded uk-padding-small">
                                                    <div class="uk-grid-small" uk-grid>
                                                        <div class="uk-width-1-1">
                                                            <div class="uk-flex uk-flex-top uk-flex-between uk-form-label">
                                                                <label for="company" class="uk-form-label">Company <span class="uk-text-danger">*</span></label>
                                                                <button 
                                                                    type="button" 
                                                                    :class="[disabledButtonDeleteVacancy ? 'close-button-disabled' : 'close-button']" 
                                                                    :disabled="disabledButtonDeleteVacancy" 
                                                                    @click="deleteJobVacancy({ index })"
                                                                >
                                                                    <img :src="`${images}/close-black-18.svg`" alt="" />
                                                                </button>
                                                            </div>
                                                            <div class="margin-top-5-px">
                                                                <multiselect
                                                                    id="company"
                                                                    :multiple="false"
                                                                    :searchable="true"
                                                                    :internal-search="false"
                                                                    :loading="companySearchLoading"
                                                                    :options="companyOptions"
                                                                    track-by="_id"
                                                                    label="name"
                                                                    placeholder="Choose / Type Here..."
                                                                    data-vv-name="company"
                                                                    data-vv-scope="selectedLiveContent"
                                                                    v-validate="{ required: true }"
                                                                    v-model="vacancy.company"
                                                                    @search-change="filterCompanyOptionsByName"
                                                                    @select="filterCompanyRoleOfficeVacancyOptionsByCompanyId"
                                                                    @input="
                                                                        vacancy.company_role = null, 
                                                                        vacancy.company_office = null, 
                                                                        vacancy.vacancy = null
                                                                    "
                                                                />
                                                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.company')">
                                                                    {{ errors.first('selectedLiveContent.company') }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="uk-width-1-1">
                                                            <label for="jobRole" class="uk-form-label">Job Role <span class="uk-text-danger">*</span></label>
                                                            <div class="margin-top-5-px">
                                                                <multiselect
                                                                    id="jobRole"
                                                                    :multiple="false"
                                                                    :searchable="true"
                                                                    :internal-search="false"
                                                                    :options="companyRoleOptions"
                                                                    :loading="companyRoleSearchLoading"
                                                                    track-by="_id"
                                                                    label="name"
                                                                    placeholder="Choose / Type Here..."
                                                                    data-vv-name="company_role"
                                                                    data-vv-scope="selectedLiveContent"
                                                                    v-validate="{ required: true }"
                                                                    v-model="vacancy.company_role"
                                                                    :disabled="!vacancy.company"
                                                                    @search-change="filterCompanyRoleOptionsByName"
                                                                    @select="filterVacancyOptionsByCompanyRoleId"
                                                                    @input="vacancy.vacancy = null"
                                                                />
                                                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.company_role')">
                                                                    {{ errors.first('selectedLiveContent.company_role') }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="uk-width-1-1">
                                                            <label for="office" class="uk-form-label">Office <span class="uk-text-danger">*</span></label>
                                                            <div class="margin-top-5-px">
                                                                <multiselect
                                                                    id="office"
                                                                    :multiple="false"
                                                                    :searchable="true"
                                                                    :internal-search="false"
                                                                    :options="companyOfficeOptions"
                                                                    :loading="companyOfficeSearchLoading"
                                                                    track-by="_id"
                                                                    label="name"
                                                                    placeholder="Choose / Type Here..."
                                                                    data-vv-name="company_office"
                                                                    data-vv-scope="selectedLiveContent"
                                                                    v-validate="{ required: true }"
                                                                    v-model="vacancy.company_office"
                                                                    :disabled="!vacancy.company"
                                                                    @search-change="filterCompanyOfficeOptionsByName"
                                                                    @select="filterVacancyOptionsByCompanyOfficeId"
                                                                    @input="vacancy.vacancy = null"
                                                                />
                                                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.company_office')">
                                                                    {{ errors.first('selectedLiveContent.company_office') }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="uk-width-1-1">
                                                            <label for="vacancyTitle" class="uk-form-label">Vacancy Title <span class="uk-text-danger">*</span></label>
                                                            <div class="margin-top-5-px">
                                                                <multiselect
                                                                    id="vacancyTitle"
                                                                    :multiple="false"
                                                                    :searchable="true"
                                                                    :internal-search="false"
                                                                    :options="vacancyOptions"
                                                                    :loading="jobSearchLoading"
                                                                    track-by="_id"
                                                                    label="title"
                                                                    placeholder="Choose / Type Here..."
                                                                    data-vv-name="vacancy"
                                                                    data-vv-scope="selectedLiveContent"
                                                                    v-validate="{ required: true }"
                                                                    v-model="vacancy.vacancy"
                                                                    :disabled="(!vacancy.company || !vacancy.company_role) && (!vacancy.company || !vacancy.company_office)"
                                                                    @search-change="filterVacancyOptionsByTitle"
                                                                    @input="filterVacancyOptionsById"
                                                                />
                                                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedLiveContent.vacancy')">
                                                                    {{ errors.first('selectedLiveContent.vacancy') }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="showButtonAddMoreVacancy" class="uk-width-1-1">
                                                <div class="uk-flex uk-flex-center uk-flex-middle">
                                                    <button 
                                                        type="button" 
                                                        class="uk-button uk-button-primary" 
                                                        @click="addMoreVacancy"
                                                    >Add More Vacancy</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-right uk-flex-middle">
                                <LoaderButton v-if="createOrEditLiveContentButtonSaveLoading" />
                                <button 
                                    v-else
                                    type="submit" 
                                    class="uk-button uk-button-primary"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit live content modal box -->

                <!-- Confirmation delete live content modal box -->
                <div id="confirmation-delete-live-content-modal-box" class="uk-flex-top confirmation-delete-live-content-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Are you sure to delete this data ?</h2>
                        </div>
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">Title: </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ selectedLiveContent.title || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">Video Link (Youtube): </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ selectedLiveContent.video_url || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">PIC Community Live Content: </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ arrayPreview({ 
                                                data: selectedLiveContent && selectedLiveContent.pic_community_ids ? selectedLiveContent.pic_community_ids : [], 
                                                limit: selectedLiveContent.pic_community_ids.length 
                                            }) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">Date: </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ dateComplete({ date: selectedLiveContent.date }) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">Start Time (WIB): </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ hourMinuteFromDate({ date: selectedLiveContent.start_time }) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-2">
                                            <p class="uk-text-bold">End Time (WIB): </p>
                                        </div>
                                        <div class="uk-width-1-2 uk-text-break">
                                            <p>{{ hourMinuteFromDate({ date: selectedLiveContent.end_time }) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-right uk-flex-middle">
                            <LoaderButton v-if="confirmationDeleteLiveContentButtonLoading" />
                            <button v-else type="button" class="uk-button uk-button-danger uk-border-rounded" @click="doDeleteLiveContent">Delete</button>
                        </div>
                    </div>
                </div>
                <!-- End confirmation delete live content modal box -->

                <!-- Confirmation switch live content modal box -->
                <div id="confirmation-switch-status-live-content-modal-box" class="uk-flex-top confirmation-switch-status-live-content-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 v-if="selectedLiveContent.status == 1" class="uk-modal-title">Are you sure you want to turn on live content ?</h2>
                            <h2 v-else-if="selectedLiveContent.status == 2" class="uk-modal-title">Are you sure you want to end live content?</h2>
                        </div>
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p class="uk-text-bold">Title: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ selectedLiveContent.title || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p class="uk-text-bold">PIC: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ arrayPreview({ 
                                                data: selectedLiveContent && selectedLiveContent.pic_community_ids ? selectedLiveContent.pic_community_ids : [], 
                                                limit: selectedLiveContent.pic_community_ids.length 
                                            }) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-4">
                                            <p class="uk-text-bold">Date: </p>
                                        </div>
                                        <div class="uk-width-3-4 uk-text-break">
                                            <p>{{ dateComplete3({ date: selectedLiveContent.date }) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-right uk-flex-middle">
                            <LoaderButton v-if="confirmationSwitchStatusLiveContentButtonLoading" />
                            <template v-else>
                                <button 
                                    v-if="selectedLiveContent.status == 1"
                                    type="button" 
                                    class="uk-button uk-button-primary uk-border-rounded" 
                                    @click="doSwitchStatusLiveContent({ id: selectedLiveContent.id || null })"
                                >Turn On</button>
                                <button 
                                    v-else-if="selectedLiveContent.status == 2"
                                    type="button" 
                                    class="uk-button uk-button-danger uk-border-rounded" 
                                    @click="doSwitchStatusLiveContent({ id: selectedLiveContent.id || null })"
                                >Finish</button>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- End confirmation switch live content modal box -->
            </template>
        </template>
        <NoInternetConnection v-else />
    </div>
</template>

<script>
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { mapActions } from 'vuex';
import formatter from '@/utils/formatter';
import { PREFIX_IMAGE } from '@/utils/constant';
import { DateTime } from 'luxon';
import { io } from 'socket.io-client';
import XLSX from 'xlsx';

export default {
    name: 'LiveContent',
    components: {
        Datetime,
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LabelStatus: () => import('@/components/globals/LabelStatus')
    },
    data() {
        return {
            dateTime: DateTime,
            images: PREFIX_IMAGE,
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            picAdminCommunitySearchLoading: false,
            companySearchLoading: false,
            companyRoleSearchLoading: false,
            companyOfficeSearchLoading: false,
            jobSearchLoading: false,
            createOrEditLiveContentButtonSaveLoading: false,
            confirmationDeleteLiveContentButtonLoading: false,
            confirmationSwitchStatusLiveContentButtonLoading: false,
            picCommunityOptions: [],
            companyOptions: [],
            companyRoleOptions: [],
            companyOfficeOptions: [],
            vacancyOptions: [],
            modalBoxTitle: '',
            modalBoxType: 'create',
            selectedLiveContent: {
                id: '',
                title: '',
                video_url: '',
                pic_community_ids: [],
                date: '',
                start_time: '',
                end_time: '',
                is_hiring: false,
                is_midnight: false,
                vacancies: [{
                    company: null,
                    company_role: null,
                    company_office: null,
                    vacancy: null
                }],
                status: 1,
            },
            liveStreamData: {
                docs: [],
                totalDocs: 0
            },
            meta: {
                limit: 10,
                page: 1,
                title: ''
            },
            metaPicCommunityOptions: {
                fullname: '',
                roles: 'admin-community'
            },
            metaCompanyOptions: {
                name: ''
            },
            metaCompanyRoleOptions: {
                name: '',
                company_id: ''
            },
            metaCompanyOfficeOptions: {
                name: '',
                company_id: ''
            },
            metaVacancyOptions: {
                title: '',
                status: 1,
                company_id: '',
                company_role_id: '',
                company_office_id: '',
                type: 'partner'
            },
            liveStreamSocket: null,
            liveStreamUrl: process.env.VUE_APP_SOCKET_IO,
            downloadReportLoading: false
        };
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setLiveStreamData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async metaPicCommunityOptions() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.picAdminCommunitySearchLoading = true;
                await this.setPicCommunityOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.picAdminCommunitySearchLoading = false;
            }
        },
        async metaCompanyOptions() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.companySearchLoading = true;
                await this.setCompanyOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companySearchLoading = false;
            }
        },
        async metaCompanyRoleOptions() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.companyRoleSearchLoading = true;
                await this.setCompanyRoleOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyRoleSearchLoading = false;
            }
        },
        async metaCompanyOfficeOptions() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.companyOfficeSearchLoading = true;
                await this.setCompanyOfficeOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyOfficeSearchLoading = false;
            }
        },
        async metaVacancyOptions() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.jobSearchLoading = true;
                await this.setVacancyOptions();
                this.filterVacancyOptionsById();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.jobSearchLoading = false;
            }
        },
        'selectedLiveContent.is_hiring'(val) {
            if (val === false) {
                this.selectedLiveContent.vacancies = [{
                    company: null,
                    company_role: null,
                    company_office: null,
                    vacancy: null
                }];
            }
        }
    },
    computed: {
        disabledButtonDeleteVacancy() {
            let data = false;

            if (this.selectedLiveContent.vacancies.length < 2) {
                data = true;
            }

            return data;
        },
        showButtonAddMoreVacancy() {
            let data = false;

            if (this.selectedLiveContent.vacancies.length < 10) {
                data = true;
            }

            return data;
        },
        minDateInput() {
            const date = new Date();
            const startOfDay = formatter.startOfDay(date);
            return `${new Date(startOfDay).toISOString()}`;
        },
        minStartTime() {
            let date = null;
            if (this.selectedLiveContent.date) {
                const today = new Date().setHours(0, 0, 0, 0);
                const selectedDate = new Date(this.selectedLiveContent.date).setHours(0, 0, 0, 0);
                if (today === selectedDate) {
                    const newDate = new Date();
                    const minutes = newDate.getMinutes();
                    if (minutes > 0 && minutes < 15) {
                        newDate.setMinutes(15, 0, 0);
                    } else if (minutes > 15 && minutes < 30) {
                        newDate.setMinutes(30, 0, 0);
                    } else if (minutes > 30 && minutes < 45) {
                        newDate.setMinutes(45, 0, 0);
                    } else if (minutes > 45 && minutes !== 0) {
                        newDate.setHours(newDate.getHours() + 1);
                        newDate.setMinutes(0, 0, 0);
                    }
                    date = newDate.toISOString();
                }
            }
            return date;
        },
        maxStartTime() {
            const date = new Date();
            date.setHours(23, 30, 0, 0);
            return date.toISOString();
        },
        minEndTime() {
            let date = null;
            if (this.selectedLiveContent.start_time) {
                const selectedStartTime = this.selectedLiveContent.start_time;
                const addDate = formatter.dateAdd({ date: selectedStartTime, amount: 15, unit: 'minutes' });
                const newDate = new Date(addDate);
                date = newDate.toISOString();
            }
            return date;
        },
        maxEndTime() {
            const date = new Date();
            date.setHours(23, 45, 0, 0);
            return date.toISOString();
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-live-content-modal-box')) window.UIkit.modal('.create-or-edit-live-content-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-live-content-modal-box')) window.UIkit.modal('.confirmation-delete-live-content-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-switch-status-live-content-modal-box')) window.UIkit.modal('.confirmation-switch-status-live-content-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading = true;
            await Promise.all([
                this.setPicCommunityOptions(),
                this.setCompanyOptions(),
                this.setCompanyRoleOptions(),
                this.setCompanyOfficeOptions(),
                this.setVacancyOptions(),
                this.setLiveStreamData()
            ]);
            
            this.liveStreamSocket = io(this.liveStreamUrl, { autoConnect: false });
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            /**
             * Options API
             */
            getUserOptionList: 'userV4/getUserOptionList',
            getCompanyOptionList: 'companyV4/getCompanyOptionList',
            getCompanyRoleOptionList: 'companyRoleV4/getCompanyRoleOptionList',
            getCompanyOfficeOptions: 'companyOfficeV4/getCompanyOfficeOptions',
            getJobOptionList: 'jobV4/getJobOptionList',

            /**
             * Live stream API
             */
            createLiveStream: 'liveStreamV4/createLiveStream',
            switchStatusLiveStream: 'liveStreamV4/switchStatusLiveStream',
            editLiveStream: 'liveStreamV4/editLiveStream',
            deleteLiveStream: 'liveStreamV4/deleteLiveStream',
            getLiveStreamListPaginate: 'liveStreamV4/getLiveStreamListPaginate',
            liveStreamDownloadReport: 'liveStreamV4/liveStreamDownloadReport',
        }),
        async setPicCommunityOptions() {
            try {
                const response = await this.getUserOptionList(this.metaPicCommunityOptions);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.picCommunityOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyOptions() {
            try {
                const response = await this.getCompanyOptionList(this.metaCompanyOptions);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.companyOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyRoleOptions() {
            try {
                const response = await this.getCompanyRoleOptionList(this.metaCompanyRoleOptions);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.companyRoleOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyOfficeOptions() {
            try {
                const response = await this.getCompanyOfficeOptions(this.metaCompanyOfficeOptions);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.companyOfficeOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setVacancyOptions() {
            try {
                const response = await this.getJobOptionList(this.metaVacancyOptions);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.vacancyOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setLiveStreamData() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.getLiveStreamListPaginate(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const docs = response.data.result.docs || [];
                    const totalDocs = response.data.result.totalDocs || 0;
                    this.liveStreamData.docs = docs;
                    this.liveStreamData.totalDocs = totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterLiveStream() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async showCreateOrEditLiveContentModalBox({ type, id }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedLiveContent();
                this.modalBoxType = type;
                if (type === 'create') {
                    this.modalBoxTitle = 'Create Live Content';
                } else if (type === 'edit') {
                    this.modalBoxTitle = 'Edit Live Content';
                    const findLiveContent = this.findLiveContent({ id });
                    this.fillSelectedLiveContent({ data: findLiveContent });
                }
                await window.UIkit.modal('#create-or-edit-live-content-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationDeleteLiveContentModalBox({ id }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedLiveContent();
                const findLiveContent = this.findLiveContent({ id });
                this.fillSelectedLiveContent({ data: findLiveContent });
                await window.UIkit.modal('#confirmation-delete-live-content-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationSwitchStatusLiveContentModalBox({ id }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedLiveContent();
                const findLiveContent = this.findLiveContent({ id });
                this.fillSelectedLiveContent({ data: findLiveContent });
                await window.UIkit.modal('#confirmation-switch-status-live-content-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateLiveStream({ payloads }) {
            try {
                const response = await this.createLiveStream(payloads);
                if (response && response.data && response.data.status === 'OK') {
                    notificationSuccess('Live content created successfully');
                } else {
                    notificationDangerCustom('Live content failed to create');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doSwitchStatusLiveContent({ id }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.confirmationSwitchStatusLiveContentButtonLoading = true;
                this.hideDropdown();
                const response = await this.switchStatusLiveStream(id);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const status = response.data.result.status;
                    const result = response.data.result;

                    this.liveStreamSocketConnect({ liveStreamId: 'live-stream' });
                    this.liveStreamSocketEmit({ name: 'live-stream:switch-status', payload: result });

                    if (status == 2) {
                        notificationSuccess('Live content turned on successfully');
                    } else if (status == 3) {
                        notificationSuccess('End live content successfully');
                    }
                }
                await window.UIkit.modal('#confirmation-switch-status-live-content-modal-box').hide();
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
                this.confirmationSwitchStatusLiveContentButtonLoading = false;
            }
        },
        async doEditLiveContent({ payloads }) {
            try {
                const response = await this.editLiveStream(payloads);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;

                    this.liveStreamSocketConnect({ liveStreamId: 'live-stream' });
                    this.liveStreamSocketEmit({ name: 'live-stream:edit', payload: result });

                    notificationSuccess('Live content updated successfully');
                } else {
                    notificationDangerCustom('Live content failed to update');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDeleteLiveContent() {
            try {
                this.confirmationDeleteLiveContentButtonLoading = true;
                const response = await this.deleteLiveStream(this.selectedLiveContent.id);
                if (response && response.data && response.data.status === 'OK') {
                    notificationSuccess('Live content deleted successfully');
                } else {
                    notificationDangerCustom('Live content failed to delete');
                }
                await window.UIkit.modal('#confirmation-delete-live-content-modal-box').hide();
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.confirmationDeleteLiveContentButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async createOrEditLiveContent() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                const validate = await this.$validator.validate('selectedLiveContent.*');
                if (!validate || this.$validator.errors.any('selectedLiveContent')) return;

                this.createOrEditLiveContentButtonSaveLoading = true;

                const payloads = {
                    id: this.selectedLiveContent.id,
                    title: this.selectedLiveContent.title || '',
                    video_url: '',
                    pic_community_ids: [],
                    date: '',
                    start_time: {
                        hour: '',
                        minute: ''
                    },
                    end_time: {
                        hour: '',
                        minute: ''
                    },
                    is_hiring: this.selectedLiveContent.is_hiring,
                    is_midnight: this.selectedLiveContent.is_midnight,
                    job_ids: []
                };

                if (this.selectedLiveContent.video_url) {
                    const videoUrl = `https://www.youtube.com/embed/${this.selectedLiveContent.video_url}`;
                    payloads.video_url = videoUrl;
                } else {
                    payloads.video_url = '';
                }

                if (this.selectedLiveContent.pic_community_ids.length > 0) {
                    const distinctPicCommunityIds = [...new Set(this.selectedLiveContent.pic_community_ids.map((data) => String((data && data._id))))];
                    payloads.pic_community_ids = distinctPicCommunityIds;
                } else {
                    payloads.pic_community_ids = [];
                }

                if (this.selectedLiveContent.date) {
                    const date = this.dateShort({ date: this.selectedLiveContent.date });
                    payloads.date = date;
                } else {
                    payloads.date = '';
                }

                if (this.selectedLiveContent.start_time) {
                    const hourMinute = this.hourMinute({ date: this.selectedLiveContent.start_time });
                    payloads.start_time = hourMinute;
                } else {
                    payloads.start_time.hour = '';
                    payloads.start_time.minute = '';
                }

                if (this.selectedLiveContent.end_time) {
                    const hourMinute = this.hourMinute({ date: this.selectedLiveContent.end_time });
                    payloads.end_time = hourMinute;
                } else {
                    payloads.end_time.hour = '';
                    payloads.end_time.minute = '';
                }

                if (this.selectedLiveContent.is_hiring === true && this.selectedLiveContent.vacancies.length > 0) {
                    const distinctJobId = [...new Set(this.selectedLiveContent.vacancies.map((vacancy) => String((vacancy && vacancy.vacancy && vacancy.vacancy._id))))];
                    payloads.job_ids = distinctJobId;
                } else {
                    delete payloads.job_ids;
                }

                if (this.modalBoxType === 'create') {
                    delete payloads.id;
                    await this.doCreateLiveStream({ payloads });
                } else if (this.modalBoxType === 'edit') {
                    await this.doEditLiveContent({ payloads });
                }

                this.resetSelectedLiveContent();
                await window.UIkit.modal('#create-or-edit-live-content-modal-box').hide();

                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditLiveContentButtonSaveLoading = false;
                this.tableLoading = false;
            }
        },
        async filterVacancyOptionsById() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                await this.setVacancyOptions();
                const distinctJobId = [...new Set(this.selectedLiveContent.vacancies.map((vacancy) => String((vacancy && vacancy.vacancy && vacancy.vacancy._id))))];
                this.vacancyOptions = this.vacancyOptions.filter((vacancy) => !distinctJobId.includes(vacancy._id));
            } catch (error) {
                notificationDanger(error);
            }
        },
        async deleteJobVacancy({ index }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.selectedLiveContent.vacancies.forEach((_, vacancyIndex, arr) => {
                    if (index === vacancyIndex) {
                        return arr.splice(index, 1);
                    }
                });
                await this.setVacancyOptions();
                const distinctJobId = [...new Set(this.selectedLiveContent.vacancies.map((vacancy) => String((vacancy && vacancy.vacancy && vacancy.vacancy._id))))];
                this.vacancyOptions = this.vacancyOptions.filter((vacancy) => !distinctJobId.includes(vacancy._id));
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadReport({ id }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.downloadReportLoading = true;
                this.hideDropdown();
                const response = await this.liveStreamDownloadReport(id);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    const liveStreamDetail = result && result.live_stream_detail || null;
                    const liveStreamVisitor = result && result.live_stream_visitor || [];
                    const liveStreamChat = result && result.live_stream_chat || [];

                    const liveStreamVisitorDataExcel = this.liveStreamVisitorExcelData({ liveStreamDetail, liveStreamVisitor });
                    const liveStreamChatDataExcel = this.liveStreamChatExcelData({ liveStreamDetail, liveStreamChat });

                    const wb = XLSX.utils.book_new();
                    const loginVisitor = XLSX.utils.json_to_sheet(liveStreamVisitorDataExcel);
                    const chat = XLSX.utils.json_to_sheet(liveStreamChatDataExcel);

                    XLSX.utils.book_append_sheet(wb, loginVisitor, 'Login Visitor');
                    XLSX.utils.book_append_sheet(wb, chat, 'Chat');
                    XLSX.writeFile(wb, `Live stream report_${liveStreamDetail?.title}_${this.dateComplete({ date: liveStreamDetail?.date || null })}.xls`);

                    notificationSuccess('Download report successfully');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadReportLoading = false;
            }
        },
        liveStreamVisitorExcelData({ liveStreamDetail, liveStreamVisitor }) {
            if (Array.isArray(liveStreamVisitor) && liveStreamVisitor.length < 1) {
                const payload = {};
                const dataExcel = [];

                payload['LIVE_Content_ID'] = null;
                payload['LIVE_Content_Title'] = null;
                payload['Date'] = null;
                payload['Start_Time'] = null;
                payload['End_Time'] = null;
                payload['Visitor: Login User'] = null;
                payload['Roles_Type'] = null;
                payload['Email'] = null;
                payload['Phone_Number'] = null;
                payload['Engagement Duration'] = null;
                payload['Amount_of_LIVE_Chat'] = null;
                dataExcel.push(payload);

                return dataExcel;
            } else {
                const dataExcel = [];

                liveStreamVisitor.forEach((visitor) => {
                    const payload = {};

                    payload['LIVE_Content_ID'] = liveStreamDetail?._id || null;
                    payload['LIVE_Content_Title'] = liveStreamDetail?.title || null;
                    payload['Date'] = this.dateComplete({ date: liveStreamDetail?.date || null }) || null;
                    payload['Start_Time'] = this.hourMinuteFromObject({ data: liveStreamDetail?.start_time }) || null;
                    payload['End_Time'] = this.hourMinuteFromObject({ data: liveStreamDetail?.end_time }) || null;
                    payload['Visitor: Login User'] = liveStreamDetail?.visitor || 0;
                    payload['Roles_Type'] = visitor?.user_roles || null;
                    payload['Email'] = visitor?.user_email || null;
                    payload['Phone_Number'] = visitor?.user_phone_number || null;
                    payload['Engagement Duration'] = this.engagementDurationFormatter(visitor?.engagement_duration) || null;
                    payload['Amount_of_LIVE_Chat'] = visitor?.amount_of_live_chat || 0;
                    dataExcel.push(payload);
                });

                return dataExcel;
            }
        },
        liveStreamChatExcelData({ liveStreamDetail, liveStreamChat }) {
            if (Array.isArray(liveStreamChat) && liveStreamChat.length < 1) {
                const payload = {};
                const dataExcel = [];

                payload['LIVE_Content_ID'] = null;
                payload['LIVE_Content_Title'] = null;
                payload['Date'] = null;
                payload['Start_Time'] = null;
                payload['End_Time'] = null;
                payload['Visitor: Login User'] = null;
                payload['Roles_Type'] = null;
                payload['Email'] = null;
                payload['Phone_Number'] = null;
                payload['Messages'] = null;
                payload['Time_Stamp_(WIB)'] = null;
                dataExcel.push(payload);

                return dataExcel;
            } else {
                const dataExcel = [];

                liveStreamChat.forEach((visitor) => {
                    const payload = {};

                    payload['LIVE_Content_ID'] = liveStreamDetail?._id || null;
                    payload['LIVE_Content_Title'] = liveStreamDetail?.title || null;
                    payload['Date'] = this.dateComplete({ date: liveStreamDetail?.date || null }) || null;
                    payload['Start_Time'] = this.hourMinuteFromObject({ data: liveStreamDetail?.start_time }) || null;
                    payload['End_Time'] = this.hourMinuteFromObject({ data: liveStreamDetail?.end_time }) || null;
                    payload['Visitor: Login User'] = liveStreamDetail?.visitor || 0;
                    payload['Roles_Type'] = visitor?.user_roles || null;
                    payload['Email'] = visitor?.user_email || null;
                    payload['Phone_Number'] = visitor?.user_phone_number || null;
                    payload['Messages'] = visitor?.messages || null;
                    payload['Time_Stamp_(WIB)'] = this.formatterHourMinuteFromDate({ date: visitor?.created_at });
                    dataExcel.push(payload);
                });

                return dataExcel;
            }
        },
        goToLiveContentDetail({ id }) {
            this.$router.push(`/admin/community/live-content/${id}`);
        },
        liveStreamSocketConnect({ liveStreamId }) {
            if (!this.liveStreamSocket) throw new Error('Live stream socket is not found');
            this.liveStreamSocket.auth = { liveStreamID: liveStreamId };
            this.liveStreamSocket.connect();
        },
        liveStreamSocketEmit({ name, payload }) {
            if (!this.liveStreamSocket) throw new Error('Live stream socket is not connected');
            this.liveStreamSocket.emit(name, payload);
        },
        changeDate() {
            this.selectedLiveContent.start_time = '';
            this.selectedLiveContent.end_time = '';
            this.$validator.reset();
        },
        changeStartTime() {
            this.selectedLiveContent.end_time = '';
            this.$validator.reset();
        },
        filterPicCommunityByFullname(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaPicCommunityOptions = {
                ...this.metaPicCommunityOptions,
                fullname: query
            };
        },
        filterCompanyOptionsByName(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaCompanyOptions = {
                ...this.metaCompanyOptions,
                name: query
            };
        },
        filterCompanyRoleOfficeVacancyOptionsByCompanyId(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaCompanyRoleOptions = {
                ...this.metaCompanyRoleOptions,
                company_id: query._id || null
            };
            this.metaCompanyOfficeOptions = {
                ...this.metaCompanyOfficeOptions,
                company_id: query._id || null
            };
            this.metaVacancyOptions = {
                ...this.metaVacancyOptions,
                company_id: query._id || null
            };
        },
        filterCompanyRoleOptionsByName(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaCompanyRoleOptions = {
                ...this.metaCompanyRoleOptions,
                name: query
            };
        },
        filterCompanyOfficeOptionsByName(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaCompanyOfficeOptions = {
                ...this.metaCompanyOfficeOptions,
                name: query || null
            };
        },
        filterVacancyOptionsByTitle(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaVacancyOptions = {
                ...this.metaVacancyOptions,
                title: query
            };
        },
        filterVacancyOptionsByCompanyRoleId(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaVacancyOptions = {
                ...this.metaVacancyOptions,
                company_role_id: query._id || null
            };
        },
        filterVacancyOptionsByCompanyOfficeId(query) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.metaVacancyOptions = {
                ...this.metaVacancyOptions,
                company_office_id: query._id || null
            };
        },
        findLiveContent({ id }) {
            return this.liveStreamData.docs.find((data) => (data && data._id) && (data._id == id));
        },
        addMoreVacancy() {
            if (!navigator.onLine) return this.internetConnection = false;
            this.selectedLiveContent.vacancies.push({
                company: null,
                company_role: null,
                company_office: null,
                vacancy: null
            });
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', { delayHide: 0 });
            closeDropdown ? closeDropdown.hide() : null;
        },
        fillSelectedLiveContent({ data }) {
            this.selectedLiveContent.id = data && data?._id || '';
            this.selectedLiveContent.title = data && data?.title || '';
            this.selectedLiveContent.date = data && data?.date || '';
            this.selectedLiveContent.is_hiring = data && data?.is_hiring || false;
            this.selectedLiveContent.is_midnight = data && data?.is_midnight || false;
            this.selectedLiveContent.status = data && data?.status || 1;

            this.selectedLiveContent.start_time = this.dateComplete2({ 
                hour: data && data?.start_time?.hour || 0, 
                minute: data && data?.start_time?.minute || 0
            });

            this.selectedLiveContent.end_time = this.dateComplete2({ 
                hour: data && data?.end_time?.hour || 0, 
                minute: data && data?.end_time?.minute || 0
            });
            
            if (data && data.video_url) {
                const videoUrlRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                const match = data?.video_url.match(videoUrlRegex);
                if (match && match[7]) {
                    this.selectedLiveContent.video_url = match[7];
                } else {
                    this.selectedLiveContent.video_url = '';
                }
            } else {
                this.selectedLiveContent.video_url = '';
            }

            if (data && Array.isArray(data.community_pic) && data.community_pic.length > 0) {
                this.selectedLiveContent.pic_community_ids = data?.community_pic;
            } else {
                this.selectedLiveContent.pic_community_ids = [];
            }

            if (data && Array.isArray(data.job_vacancies) && data.job_vacancies.length > 0) {
                const jobVacancies = data.job_vacancies.map((item) => ({
                    company: {
                        _id: item?.company?._id || null,
                        name: item?.company?.name || null
                    },
                    company_office: {
                        _id: item?.company_office?._id || null,
                        name: item?.company_office?.name || null
                    },
                    company_role: {
                        _id: item?.company_role?._id || null,
                        name: item?.company_role?.name || null
                    },
                    vacancy: {
                        _id: item?._id || null,
                        title: item?.title || null,
                        type: item?.type || 'myrobin',
                        company_id: item?.company?._id || null,
                        company_office_id: item?.company_office?._id || null,
                        company_role_id: item?.company_role?._id || null,
                        created_at: item?.created_at || null,
                    }
                }));

                this.selectedLiveContent.vacancies = jobVacancies;
            } else {
                this.selectedLiveContent.vacancies = [{
                    company: null,
                    company_role: null,
                    company_office: null,
                    vacancy: null
                }];
            }
        },
        resetSelectedLiveContent() {
            this.selectedLiveContent.id = '';
            this.selectedLiveContent.title = '';
            this.selectedLiveContent.video_url = '';
            this.selectedLiveContent.pic_community_ids = [];
            this.selectedLiveContent.date = '';
            this.selectedLiveContent.start_time = '';
            this.selectedLiveContent.end_time = '';
            this.selectedLiveContent.is_hiring = false;
            this.selectedLiveContent.is_midnight = false;
            this.selectedLiveContent.vacancies = [{
                company: null,
                company_role: null,
                company_office: null,
                vacancy: null
            }];
            this.selectedLiveContent.status = 1;
            this.$validator.reset();
        },
        dateComplete({ date }) {
            const dateInstance = new Date(date);
            if (dateInstance instanceof Date && !isNaN(dateInstance)) {
                return formatter.dateComplete(dateInstance);
            } else {
                return 'Invalid date';
            }
        },
        dateComplete2({ hour, minute }) {
            const hourMinute = { hour, minute };
            return this.dateTime.fromObject(hourMinute).toISO();
        },
        dateComplete3({ date }) {
            const dateInstance = new Date(date);
            if (dateInstance instanceof Date && !isNaN(dateInstance)) {
                return formatter.dateComplete2(dateInstance);
            } else {
                return 'Invalid date';
            }
        },
        dateShort({ date }) {
            const dateInstance = new Date(date);
            if (dateInstance instanceof Date && !isNaN(dateInstance)) {
                return formatter.dateShort(dateInstance);
            } else {
                return 'Invalid date';
            }
        },
        hourMinuteFromObject({ data }) {
            if (data) {
                return formatter.hourMinuteFromObject(data);
            } else {
                return '--:--';
            }
        },  
        hourMinuteFromDate({ date }) {
            const dateInstance = new Date(date);
            if (dateInstance instanceof Date && !isNaN(dateInstance)) {
                return formatter.hourMinuteFromDate(dateInstance.toISOString());
            } else {
                return 'Invalid date';
            }
        },
        hourMinute({ date }) {
            const dateInstance = new Date(date);
            if (dateInstance instanceof Date && !isNaN(dateInstance)) {
                const hour = new Date(dateInstance).getHours();
                const minute = new Date(dateInstance).getMinutes();
                return {
                    hour,
                    minute
                };
            } else {
                return 'Invalid date';
            }
        },
        stringPreview({ text, limit }) {
            if (typeof text !== "string") throw new Error("Text must be a string");
            if (typeof limit !== "number") throw new Error("Limit must ");

            let string = '';
            if (text.length > limit) {
                string = `${text.substring(0, limit)}...`;
            } else {
                string = text;
            }

            return string;
        },
        arrayPreview({ data, limit }) {
            if (!Array.isArray(data)) throw new Error('Data must be a array');
            if (typeof limit !== "number") throw new Error('Limit must be a number');
            
            let label = '';
            if (data.length < limit) {
                data.forEach((element) => {
                    if (label.length < 1) {
                        label += element.fullname || '-';
                    } else {
                        label += `, ${element.fullname || '-'}`;
                    }
                });
            } else {
                data.forEach((element, index) => {
                    if (index < limit) {
                        if (label.length < 1) {
                            label += element.fullname || '-';
                        } else {
                            label += `, ${element.fullname || '-'}`;
                        }
                    } else if (index == limit) {
                        label += '...';
                    }
                });
            }
            
            return label;
        },
        engagementDurationFormatter(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;
            hours = hours % 24;
            
            if (minutes == 0) {
                return null;
            } else if (hours == 0) {
                return `${minutes} Minutes`;
            } else {
                return `${hours} Hours ${minutes} Minutes`;
            }
        },
        formatterHourMinuteFromDate({ date }) {
            return formatter.hourMinuteFromDate(date);
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
.border-grey {
    border: 1px solid #CCCCCC;
}

.margin-top-5-px {
    margin-top: 5px;
}

.-margin-1px-1px-0-1px {
    margin: -1px -1px 0 -1px;
}

.padding-y-15px {
    padding: 0px 15px;
}

.overflow-hidden {
    overflow: hidden;
}

.custom-input-youtube-url {
    outline: none; 
    border: none; 
    padding-left: 0px; 
    margin-left: -13.5px;
    color: #FF0000;
}

.close-button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
}

.close-button-disabled {
    border: none;
    outline: none;
    cursor: not-allowed;
    background: transparent;
}
</style>
