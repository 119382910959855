import { render, staticRenderFns } from "./coupon.vue?vue&type=template&id=2fed3ffa&scoped=true"
import script from "./coupon.vue?vue&type=script&lang=js"
export * from "./coupon.vue?vue&type=script&lang=js"
import style0 from "./coupon.vue?vue&type=style&index=0&id=2fed3ffa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fed3ffa",
  null
  
)

export default component.exports