<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <!-- Back button -->
            <button class="uk-button uk-button-default" @click="goBack">
                <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
                <span>Back</span>
            </button>
            <!-- End back button -->
            <LoaderPage v-if="isLoading" />
            <template v-else>
                <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-small-top">
                    <!-- Tab -->
                    <div class="tab-menu">
                        <button
                            type="button"
                            class="tab-menu__button"
                            :class="[activeTab === 'applicant' ? 'tab-menu__button-active' : '']"
                            @click="setActiveTabMenu({ tab: 'applicant' })"
                        >Applicant</button>
                        <button
                            type="button"
                            class="tab-menu__button"
                            :class="[activeTab === 'login-visitor' ? 'tab-menu__button-active' : '']"
                            @click="setActiveTabMenu({ tab: 'login-visitor' })"
                        >Login Visitor</button>
                        <button
                            type="button"
                            class="tab-menu__button"
                            :class="[activeTab === 'chat' ? 'tab-menu__button-active' : '']"
                            @click="setActiveTabMenu({ tab: 'chat' })"
                        >Chat</button>
                    </div>
                    <!-- End tab -->

                    <!-- Live stream title -->
                    <div class="uk-width-1-3 uk-margin-small-left" :uk-tooltip="liveStreamTitle">
                        <p class="uk-margin-remove uk-text-bolder line-clamp-2">{{ liveStreamTitle }}</p>
                    </div>
                    <!-- End live stream title -->
                </div>

                <!-- Tab content -->
                <div class="uk-width-1-1 uk-margin-top">
                    <ApplicantTab v-if="activeTab === 'applicant'" />
                    <LoginVisitorTab v-else-if="activeTab === 'login-visitor'" />
                    <ChatTab v-else-if="activeTab === 'chat'" />
                </div>
                <!-- End tab content -->
            </template>
        </template>
        <NoInternetConnection v-else />
    </div>
</template>

<script>
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { mapActions } from 'vuex';

export default {
    name: 'LiveContentDetails',
    components: {
        ApplicantTab: () => import('@/components/globals/LiveContent/ApplicantTab'),
        LoginVisitorTab: () => import('@/components/globals/LiveContent/LoginVisitorTab'),
        ChatTab: () => import('@/components/globals/LiveContent/ChatTab'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
    },
    data() {
        return {
            internetConnection: true,
            isLoading: false,
            activeTab: 'applicant',
            liveStreamData: null
        };
    },
    computed: {
        liveStreamTitle() {
            let data = '';

            if (this.liveStreamData && this.liveStreamData.title) {
                data = this.liveStreamData.title;
            }

            return data;
        }
    },
    async mounted() {
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading = true;
            await this.setFirstActiveTab();
            await this.setLiveStreamData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            /**
             * Live stream API
             */
            getLiveStreamDetail: 'liveStreamV4/getLiveStreamDetail'
        }),
        async setFirstActiveTab() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                if (!this.$route.query.tab) {
                    await this.setActiveTabMenu({ tab: 'applicant' });
                } else {
                    await this.setActiveTabMenu({ tab: this.$route.query.tab });
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveTabMenu({ tab }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                if (this.$route.query.tab !== tab) {
                    await this.setQueryURL({ tab });
                }
                this.activeTab = this.$route.query.tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL({ tab }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                await this.$router.replace({
                    path: this.$route.fullPath,
                    query: {
                        tab
                    }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setLiveStreamData() {
            try {
                const response = await this.getLiveStreamDetail(this.$route.params.id);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    this.liveStreamData = result;
                }
            } catch (error) {
                notificationDanger(error); 
            }  
        },
        goBack() {
            this.$router.back();
        }
    }
};
</script>

<style scoped>
.tab-menu {
    overflow-x: auto;
}
.tab-menu__button {
    padding: 15px 50px;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    color: #979797;
    font-size: 14px;
    font-weight: 600;
}
.tab-menu__button:hover {
    color: #0275D8;
}
.tab-menu__button-active {
    color: #0275D8;
    border-bottom: 2px solid #0275D8;
}
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
</style>
